// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    login: `/login`,
    register: `/register`,
    forgotPassword: `/forgot-password`,
    verify: `/verify`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    donations: {
      root: `${ROOTS.DASHBOARD}/donations`,
      import: `${ROOTS.DASHBOARD}/donations/import`,
      view: (id) => `${ROOTS.DASHBOARD}/donations/${id}`,
    },
    appeals: {
      root: `${ROOTS.DASHBOARD}/appeals`,
      view: (id) => `${ROOTS.DASHBOARD}/appeals/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/appeals/edit/${id}`,
    },
    campaigns: {
      root: `${ROOTS.DASHBOARD}/campaigns`,
      view: (id) => `${ROOTS.DASHBOARD}/campaigns/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/campaigns/edit/${id}`,
    },
    events: {
      root: `${ROOTS.DASHBOARD}/events`,
      view: (id) => `${ROOTS.DASHBOARD}/events/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/events/edit/${id}`,
    },
    donors: {
      root: `${ROOTS.DASHBOARD}/donors`,
      view: (id) => `${ROOTS.DASHBOARD}/donors/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/donors/edit/${id}`,
    },
    memberships: `${ROOTS.DASHBOARD}/memberships`,
    reports: {
      root: `${ROOTS.DASHBOARD}/reports`,
      donations: `${ROOTS.DASHBOARD}/reports/donations`,
      view: (id) => `${ROOTS.DASHBOARD}/reports/${id}`,
    },
    users: `${ROOTS.DASHBOARD}/users`,
    tags: `${ROOTS.DASHBOARD}/tags`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    profile: `${ROOTS.DASHBOARD}/user-profile`,
  },
};
